.campagne-modal-size{
    max-width: 800px;
    .modal-body{
        padding: 30px;
    }
}

.campagne_view{
    .campagne_item{
        text-align: center;
    }
    .contacts_campagne{
        h3{
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            width: 100%;
            padding: 15px 0;
            border-bottom: solid 1px rgb(0 0 0 / 13%);
        }
        .list-group-flush{
            .list-group-item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: nowrap;
                &.check{
                    text-decoration: line-through;
                    background-color: $primary-color-light;
                }
                p{
                    margin: 0;
                }
            }
        }
    }
}