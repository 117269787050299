.page-admin{
    .user-list-admin {
        max-height: 100%;
        width: 100%;
        overflow: auto;

        .item-content {
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            .btn-container {
                display: flex;
                flex-direction: row;

                height: min-content;
                margin-left: auto;

                button {
                    border-radius: 5px;
                    border: none;
                }
            }
        }
    }

    .admin-accord{
        display: flex;
        flex:1;
        flex-direction: column;
        gap: 10px;

        .card{
            border: none;
            border-radius: 10px;
            overflow: auto;

            &:has(.show){
                overflow: initial;
            }
            
            .admin-head{
                position: relative;
                background-color: $primary-color-dark;
                color: #fff;
                border: none;
                border-radius: 10px 10px 0 0;
                
                .btn:not(.btn-plus){
                    text-align: left;
                    color: #fff;
                    padding: 0.375rem 0rem;
                }
            }

            .list-group-item{
                padding: 0.7rem 1rem;
            }

            .adminAssign{
                .row{
                    margin: 20px auto;
                    max-width : 80%;
                    column-gap: 20px;
                    .col{
                        ul{
                            padding: 15px;
                            border: 1px solid #ced4da;
                            border-radius: 10px;
                            height: 200px;
                            overflow-y: auto;
                            list-style: none;
                            -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
                            scrollbar-width: none;  /* Hide scrollbar for Firefox */
                          
                            /* Hide scrollbar for Chrome, Safari and Opera */
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            li{
                                padding: 5px 10px;
                                margin-bottom: 5px;
                                border-radius: 10px;
                                border: solid 1px #00000010;
                                box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 8%);
                                cursor: pointer;
                                color: $primary-color-dark;
                                position: relative;
                            }
                            &.addContact{
                                li::after{
                                    content: "+";
                                    position: absolute;
                                    right: 10px;
                                }
                            }
                            &.delContact{
                                li::after{
                                    content: "-";
                                    position: absolute;
                                    right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}