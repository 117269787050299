.popup-geoloc{
    h3{
        margin: 20px 0 10px 0;
        text-align: center;
        font-size: 20px;
        color: $primary-color-dark;
        font-weight: 700;
    }

    p{
        margin-bottom: 20px;
        text-align: center;
        font-size: 16px;

        strong{
            color: $primary-color-middle;
        }
    }
}