@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;700;800&display=swap');

$header-color:#3e5b5fcc;
$primary-color-dark: #3E5B5F;
$primary-color-light:#D0FCB3;
$primary-color-middle:#4DAB10;
$primary-color-darklight:#708D91;
$client-color: rgb(200, 0, 0);
$prospect-color: rgb(0, 0, 200);

@mixin media-max($_max-width) {
    @media screen and (max-width: $_max-width) {
        &{ @content; }
    }
}

@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

@mixin media-min-max($_mmin-width, $_mmax-width) {
    @media screen and (min-width: $_mmin-width) and (max-width: $_mmax-width) {
        &{ @content; }
    }
}

@import '~leaflet/dist/leaflet.css';
@import '~bootstrap/scss/bootstrap.scss';

@import 'app.scss';
@import 'custom.scss';
@import 'admin.scss';
@import 'home.scss';
@import 'clients.scss';
@import 'campagne.scss';
@import 'listes.scss';
@import 'geoloc.scss';
@import 'weather.scss';
@import 'https://cdn.materialdesignicons.com/2.7.94/css/materialdesignicons.min.css';