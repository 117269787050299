html {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  background-image: linear-gradient(45deg, #7ED3FF, #D0FCB3);
  background-size: 100%;
  background-attachment: fixed;  
  width: 100%;
  min-width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  font-weight: 400;
}

//ALL APP PARENTS BLOCK
#root {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.App {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
//END ALL APP PARENTS BLOCK

//GLOBAL CONTAINERS MOBILE & BROWSER BLOCK
.browser-content {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  height: 100%;
  padding: 50px;
  transition: 300ms;
  overflow: auto;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
      display: none;
  }

  @include media-max(1224px){
    padding: 30px 30px 30px 90px;
  }

}

#browser-leaflet.leaflet-container { 
  @include media-max(1224px){
    width: calc(100% - 60px) !important;
    padding: 20px;
    margin-left: 60px !important;
    transition: all 300ms;
  }
}

.geoErrMsgMinimised {
  position: absolute;
  left: 250px;
  bottom: 20px;
  margin: 0;
  z-index: 1000;
  @include media-max(1224px){
    left: 80px !important;
    transition: all 300ms;
  }
}

.mobile-content {
  display: flex;
  align-content: center;
  padding: 5vw;
  transition: 300ms;
  overflow: auto;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - 50px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
      display: none;
  }
}

.App:has(.nav-mobile){
  .mobile-content {
    max-height: calc(100vh - 107px);
  }
}
//END GLOBAL CONTAINERS MOBILE & BROWSER BLOCK

//ALL APP TOOLS BLOCK
.errMsg, .succMsg {
  margin: 0 auto;
}

.offscreen {
  display: none !important;
}

.btn-plus{
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  height: 100%;
  padding: 0 15px;
  background-color: $primary-color-darklight;
  --bs-btn-hover-color:#fff;
  --bs-btn-hover-bg: #{$primary-color-darklight};
  --bs-btn-hover-border-color: #{$primary-color-darklight};
  --bs-btn-active-color:#fff;
  --bs-btn-active-bg: #{$primary-color-darklight};
  --bs-btn-active-border-color: #{$primary-color-darklight};
}
//END ALL APP TOOLS BLOCK

//MOBILE & BROWSER SIGN IN BLOCK
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 30px;
  background-color: $primary-color-dark;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  .checkbox {
    font-weight: 400;
  }

  .form-floating:focus-within {
    z-index: 2;
  }

  input {
    background-color: #fff;
    border: none;
  }
}
//END MOBILE & BROWSER SIGN IN BLOCK

//MOBILE HOME BLOCK
.mobile-home-header {
  display: flex;
  justify-content: center;
}

.mobile-home-footer {
  display: flex;
  justify-content: center;
  p{
    margin-bottom: 0;
    img{
      margin-bottom: 5px;
    }
  }
}
//END MOBILE HOME

//BROWSER SIDENAV BLOCK
.sidebar-container {
  float: left;
  display: flex;
  height: 100%;
  background-color: #3e5b5fcc;
  padding: 0 20px;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms;

  @include media-max(1224px){
    position: absolute;
    padding: 0 10px;
    z-index: 1001;
  }

  .sidebar-header {
    padding: 20px 0px;
    color: #f5f5f5;
    border-bottom: solid 1px #ffffff49;
    width: 100%;

    img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 auto 0 auto;
    }

    span {
      display: flex;
      justify-content: center;
      @include media-max(1224px){
        display: none;
      }
    }
  }

  .sidebar-profil{
    padding: 20px 0px;
    border-top: solid 1px #ffffff49;
    border-bottom: solid 1px #ffffff49;
    width: 100%;
    margin-bottom: 5px;

    @include media-max(1224px){
      border-bottom: none !important;
      padding-bottom: 0;
    }

    button {
      @include media-max(1224px){
        display: none;
      }
    }

    a{
      text-align: center;
      color: #f5f5f5;
      text-decoration: none;

      img{
        display: block;
        width: 70px;
        height: 70px;
        object-fit: cover;
        margin: 0 auto 10px auto;
        border-radius: 50%;

        @include media-max(1224px){
          width: 40px;
          height: 40px;
        }
      }

      h2{
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 5px;

        @include media-max(1224px){
          display: none;
        }
      }

      h3{
        font-size: 13px;
        font-weight: 600;
        color: $primary-color-light;
        margin-bottom: 0px;

        @include media-max(1224px){
          display: none;
        }
      }

      p{
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 5px;

        @include media-max(1224px){
          display: none;
        }
      }
    }
  }

  .sidebar-content {
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    padding: 20px 0;
    transition: all 300ms;
    flex-grow: 1;
    
    .list-items {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-left: 0;     
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
    
      .item {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        list-style-type: none;

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;         
          padding: 10px;
          border-radius: 5px;
          text-decoration: none;
          color: #f5f5f5;
          margin-bottom: 5px;
          transition: all 300ms;

          svg {
            min-width: 16px;
            transition: all 300ms;
            path{
              transition: all 300ms;
            }
          }
        }
        
        a:hover {
          background-color: $primary-color-light;
          color: $primary-color-dark;
          svg {
            color: $primary-color-dark !important;
          }
        }
  
        span {
          margin-left: 16px;
          transition: all 300ms;
          overflow: hidden;

          @include media-max(1224px){
            width: 0;
            height: 24px;
            margin-left: 0;
          }

        }
      }
    }

    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sidebar-footer {
    bottom: 0px;
    height: 40px;
    padding: 0 16px 16px 16px;
    color: $primary-color-light;
    font-size: 12px;

    @include media-max(1224px){
      display: none !important;
    }
  }

  &:hover{
    .sidebar-content{
      .list-items{ 
        .item{
          span{
            width: auto;
            height: auto;
            margin-left: 16px;
          }
        }
      }
    }
    .sidebar-profil{
      padding-bottom: 20px;
      h2,h3,p{
        display: block;
      }
    }
  }
}
//END BROWSER SIDENAV BLOCK

//BROWSER BLOCK
.create-button {
  margin: 0px 0px 5px 0px;
}

.btn-sub{
  background-color: $primary-color-light;
  color: $primary-color-dark;
  border: $primary-color-light;
  padding: 8px 12px;
  font-weight: 600;
  --bs-btn-hover-color:#{$primary-color-dark};
  --bs-btn-hover-bg: #{$primary-color-light};
  --bs-btn-hover-border-color: #{$primary-color-light};
  --bs-btn-active-color:#{$primary-color-dark};
  --bs-btn-active-bg: #{$primary-color-light};
  --bs-btn-active-border-color: #{$primary-color-light};
}

.btn{
  &:hover {
    border-color: transparent;
  }
  &:focus {
    border-color: transparent;
  }
}

.btn-icon{
  background-color: $primary-color-light;
  color: $primary-color-dark;
  border: $primary-color-light;
  font-weight: 600;
  padding: 7px 12px 8px 12px;
  --bs-btn-hover-color:#{$primary-color-dark};
  --bs-btn-hover-bg: #{$primary-color-light};
  --bs-btn-hover-border-color: #{$primary-color-light};
  --bs-btn-active-color:#{$primary-color-dark};
  --bs-btn-active-bg: #{$primary-color-light};
  --bs-btn-active-border-color: #{$primary-color-light};

  svg{
    pointer-events: none;
  }
}

.hide {
  display: none;
}

.valide {
  color: limegreen;
  float: right;
}

.invalide {
  color: red;
  float: right;
}
//END BROWSER BLOCK

//MODALS
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 10px;
  outline: 0;

  .modal-header{
    position: relative;
    background-color: #3E5B5F;
    color: #fff;
    border: none;
    border-radius: 10px 10px 0 0;
    overflow: auto;

    .h4{
      font-size: 20px;
      margin: 0 auto;
      width: 100%;
    }
  }

  .modal-body{
    .row{
      .col{
        .form-check{
          cursor: pointer;
          float: right;    
          input{
            cursor: pointer;
          }
          label{
            cursor:pointer;
          }
        }
        &:nth-of-type(2n){
          .form-check{
            float: left;
          }
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.button-as-link {
  background: none!important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  color: #069;
  margin: 0 0.25em;
  text-decoration: underline;
  cursor: pointer;
}

.button-as-link-no-style {
  background: none!important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  margin: 0 0.25em;
  cursor: pointer;
}
//END MODALS

.fc { /* the calendar root */
  width: 100%;
  margin: 0 auto;

  a {
    color: $primary-color-dark;
    text-decoration: none;
  }

  .fc-button-primary {
    background: $primary-color-dark;
  }

  .fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
    background: $primary-color-darklight;
    outline: none !important;
    box-shadow: none !important;
  }
}

.fullcalendar-container {
  background: white;
  flex-grow: 1;
  padding: 1em 1em 1em 1em;
}

.create_empty{
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 10px!important;
  margin: 10px 0 0 0;
  padding: 15px;
  border: none;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1); 
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
  column-gap: 20px;
}

.react-datepicker__input-container{
  input{
    display: block;
    width: 100%;
    padding: 1rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.nav-mobile{
  position: relative;
  display: flex;
  column-gap: 10px;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  background-color: $primary-color-dark;
  color: #fff;
  padding: 15px;
  .btn-link{
    color: #fff;
    text-decoration: none;
    padding: 0 0 3px 0;
    border: none;
  }
  h2{
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
  }
  .btn-list{
    position: absolute;
    top: 0;
    right: 55px;
    border-radius: 0;
    height: 100%;
    padding: 8px 15px;
    font-size: 21px;
    background-color: $primary-color-darklight;
    color: #fff;
  }
}