.page-listes{
    .card{
        flex-basis: 100%;
        border-radius: 10px 10px 0 0;
        border: none;
        background-color: transparent;
        margin-bottom: 15px;

        .listes-list-header{
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between; 
            width: 100%; 
            border: none;
            border-radius: 10px 10px 0 0;
            background-color: $primary-color-dark;
            height: 54px;
            color: white;
            overflow: auto;

            h2{
                color: #fff;
                font-size: 1.2rem;
                margin-bottom: 0;
            }

            .btn-list{
                position: absolute;
                top: 0;
                right: 55px;
                border-radius: 0;
                height: 100%;
                padding: 8px 15px;
                font-size: 21px;
                background-color: $primary-color-darklight;
                color: #fff;
            }
        }
    }

    .listes-list{
        display: flex;
        flex:1;
        flex-direction: column;
        gap: 10px;

        .listes-list-item{
            border: none;
            border-radius: 10px;
            background-color: #fff;

            .listes-head{
                position: relative;
                background-color: #fff;
                color: $primary-color-dark;
                border: none;
                border-radius: 10px;
                
                .btn:not(.btn-plus){
                    text-align: left;
                    color: $primary-color-dark;
                    padding: 0.375rem 0rem;
                }

                .btn-plus{
                    border-radius: 0 10px 10px 0;
                    transition: border-radius 0.15s ease-in-out;
                }
            }

            &:has(.show){
                .btn-plus{
                    border-radius: 0 10px 0 0;
                }
            }

            .listesAssign{
                .row{
                    margin: 20px auto;
                    max-width : 80%;
                    column-gap: 20px;
                    .col{
                        ul{
                            padding: 15px;
                            border: 1px solid #ced4da;
                            border-radius: 10px;
                            height: 200px;
                            overflow-y: auto;
                            list-style: none;
                            -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
                            scrollbar-width: none;  /* Hide scrollbar for Firefox */
                          
                            /* Hide scrollbar for Chrome, Safari and Opera */
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            li{
                                padding: 5px 10px;
                                margin-bottom: 5px;
                                border-radius: 10px;
                                border: solid 1px #00000010;
                                box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 8%);
                                cursor: pointer;
                                color: $primary-color-dark;
                                position: relative;
                            }
                            &.addContact{
                                li::after{
                                    content: "+";
                                    position: absolute;
                                    right: 10px;
                                }
                            }
                            &.delContact{
                                li::after{
                                    content: "-";
                                    position: absolute;
                                    right: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .commerciauxAssign{
                .row{
                    margin: 20px auto;
                    max-width : 500px;
                    column-gap: 20px;
                    .col{
                        ul{
                            padding: 15px;
                            border: 1px solid #ced4da;
                            border-radius: 10px;
                            height: 200px;
                            overflow-y: auto;
                            list-style: none;
                            -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
                            scrollbar-width: none;  /* Hide scrollbar for Firefox */
                          
                            /* Hide scrollbar for Chrome, Safari and Opera */
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            li{
                                padding: 5px 10px;
                                margin-bottom: 5px;
                                border-radius: 10px;
                                border: solid 1px #00000010;
                                box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 8%);
                                cursor: pointer;
                                position: relative;
                            
                                &.notInDaList{
                                    background-color: #fff;
                                    color: $primary-color-dark;
                                    &::after{
                                        content: "+";
                                        position: absolute;
                                        right: 10px;
                                    }
                                }
                                &.inDaList{
                                    background-color: $primary-color-dark;
                                    color: #fff;
                                    &::after{
                                        content: "-";
                                        position: absolute;
                                        right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }        
    }
}