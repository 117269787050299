.page-clients{
    .card{
        flex-basis: 100%;
        border-radius: 10px 10px 0 0;
        border: none;
        background-color: transparent;
        margin-bottom: 15px;

        .contacts-list-header{
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between; 
            width: 100%; 
            border: none;
            border-radius: 10px 10px 0 0;
            background-color: $primary-color-dark;
            height: 54px;
            color: white;
            overflow: auto;

            h2{
                color: #fff;
                font-size: 1.2rem;
                margin-bottom: 0;
                @include media-max(768px){
                    width: 100%;
                }
            }

            .btn-list{
                position: absolute;
                top: 0;
                right: 55px;
                border-radius: 0;
                height: 100%;
                padding: 8px 15px;
                font-size: 21px;
                background-color: $primary-color-darklight;
                color: #fff;
            }
        }

        .contact-infos{
            background-color: #fff;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: space-between;
            align-items: stretch;
            column-gap: 40px;
            row-gap: 20px;
            padding: 30px;
            border-radius: 0 0 10px 10px;
            @include media-max(768px){
                padding: 15px;
            }
            .person{
                max-width: calc((100% - 80px) / 3);
                @include media-max(768px){
                    max-width: 100%;
                }
            }
            .business{
                max-width: calc((100% - 80px) / 3);
                @include media-max(768px){
                    max-width: 100%;
                }
            }
            .minimap{
                width: calc((100% - 80px) / 3);
                @include media-max(768px){
                    width: 100%;
                }
            }
            .commentaires{
                flex-basis: 100%;
            }
            a{
                color: $primary-color-darklight;
                text-decoration: inherit;
                svg{
                    margin-right: 5px;
                    margin-top: -2px;
                }
            }            
            .client-color{
                color: $client-color;
            }
            
            .prospect-color{
                color: $prospect-color;
            }
        }

        .search-container{
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
            width: 100%;
            border: none;
            border-radius: 0 0 10px 10px;
            background-color: #fff;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: center;

            &.ismobile{
                border-radius: 10px;
            }

            .sub-container{
                display: flex;
                
                &:first-child{
                    flex: 1;
                }

                .search-input{
                    padding: 5px 10px;
                    margin-left: 15px;
                    min-width: 45%;
                    border-radius: 10px;
                    border-color: $primary-color-dark;
                    border-width: 1px;
                    outline: none;
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
        &.documents{
            flex-basis: 47.5%;
            margin-right: 5%;
            margin-top: 20px;
            @include media-max(768px){
                flex-basis: 100%;
                margin-right: 0;
            }
            .card-body{
                background-color: #fff;
                border-radius: 0 0 10px 10px;
                overflow: auto;
                .contacts-list-item-container{
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    &:last-child{
                        border-bottom: none;
                    }
                }
                .list-group-item{
                    border: none;
                }
            }
        }
        &.rapports{
            flex-basis: 47.5%;
            margin-top: 20px;
            @include media-max(768px){
                flex-basis: 100%;
            }
            .card-body{
                background-color: #fff;
                border-radius: 0 0 10px 10px;
                overflow: auto;
                .contacts-list-item-container{
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    &:last-child{
                        border-bottom: none;
                    }
                    .contacts-list-item-content{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-content: center;
                        p{
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                .list-group-item{
                    border: none;
                }
            }
        }
    }

    .listes-filtres{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        gap: 10px;
        margin: 20px 0;

        .btn-list{
            background-color: transparent;
            color: $primary-color-dark;
            border: solid 1px $primary-color-dark;
            padding: 8px 12px;
            font-weight: 600;
            &.active{
                background-color: $primary-color-dark;
                color: #fff;
            }
        }
    }

    .contacts-list{
        flex-basis: 100%;

        .contacts-list-item{
            position: relative;
            display: flex;
            flex-direction: row;
            border-radius: 10px;
            margin: 10px 0 0 0;
            padding: 0px;
            border: none;
            -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1); 
            box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
            @include media-max(768px){
                flex-direction: column;
            }

            .contacts-list-item-content{
                flex: 1;
                padding: 15px;
                cursor: pointer;

                .header-contact{
                    @include media-max(768px){
                        text-align: center;
                    }
                    .client-color{
                        color: $client-color;
                    }
                    
                    .prospect-color{
                        color: $prospect-color;
                    }
                }

                .body-contact{
                    position: relative;
                    display: flex;
                    gap: 10px;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    @include media-max(768px){
                        flex-direction: column;
                        text-align: center;
                    }

                    div{
                        flex: 1 3;
                        @include media-max(768px){
                            flex: 1 1;
                        }
                    }
                }
            }

            .btn-container{
                padding: 15px 0px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;
                overflow: auto;
                opacity: 0.1;
                width: 0px;
                transition: all 500ms;
                @include media-max(768px){
                    justify-content: center;
                    width: 100%;
                    opacity: 1;
                    padding: 0 15px 15px;
                }
            }

            &.hover{
                .btn-container{
                    @include media-min(769px){
                        width: 78px;
                        padding: 15px;
                        opacity: 1;
                    }
                }
            }
        }        
    }
}

.modal{
    .ficheContact{
        max-width: 80%;
        .contact-datas{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: space-between;
            align-items: stretch;
            column-gap: 20px;
            row-gap: 20px;
            .person{
                min-width: 200px;
            }
            .business{
                min-width: 200px;
            }
            .minimap{
                min-width: 320px;
            }
        }
    }
    .modal-body{
        .addFile{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: stretch;
            gap: 20px;
            padding: 20px 0;
            .searchFile{
                flex-basis: 100%;
            }
        }
        .btn_form{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: stretch;
            gap: 20px;
            padding: 20px 0;
        }
    }
    .contact-modal-size{
        max-width: 80%;
        @include media-max(575px){
            max-width: 100%;
        }
        .modal-body{
            padding: 30px;
            @include media-max(575px){
                padding: 15px;
            }
        }
    }
}