// change the theme
$theme-colors: (
  "primary": #D0FCB3,
  "danger": #ff4136,
  "dark": #333333
);

.btn,.btn:focus,.btn:active,.form-control:focus {
  outline: none !important;
  box-shadow: none;
}