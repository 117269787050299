.widget {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  column-gap: 20px;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  background-position: center center;
  background-size: cover;
}

.inline-input {
  align-items: flex-start;
  border-bottom: 1px dotted #fff;
  display: flex;
  margin: 0.5rem 0 1.5rem 0;
  &:focus-within {
    border-bottom: 1px dotted #333;
    transition: all 0.3s ease-in-out;
  }
  .mdi {
    color: #fff;
    padding: 0.2rem 0.5rem 0 0;
  }
}

.location-input {
  display: flex;
  align-items: flex-start;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  max-width: 14vw;
  outline: none;
  padding-bottom: 0.3rem;
}

.main-display {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.main-info {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  order: 0;
}

.temp-measurement {
  color: #333;
  font-size: 70px;
  font-weight: 500;
  line-height: 0.85;
}

.temp-unit {
  vertical-align: top;
}

.sub-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 0 0.8rem;
  order: 1;
}

.sub-info-title {
  font-size: 1.15rem;
  margin: 0 0 0.3rem 0;
  text-align: start;
}

.sub-info-text {
  font-size: 0.7rem;
  color: #333;
  margin: 0 0 0.4rem 0;
  text-align: start;
  min-width: 100px;
  .max-temp {
    margin: 0 0.5rem 0 -0.1rem;
  }
}

.selection-panel {
  margin: 0;
}

.selection-row {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0.2rem 0 0 0;
  flex-direction: row;
}

.selection-days {
  color: #333;
  font-size: 0.7rem;
  font-weight: 700;
  width: 3.5rem;
}

.selection-weather {
  color: #333;
  font-size: 0.7rem;
  width: 3.5rem;
}

.selection-icons {
  color: #333;
  font-size: 1.7rem;
  width: 3.5rem;
}

.selected {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.cloudy {
  background-image: url(../assets/cloudy.jpg);
}

.clear {
  background-image: url(../assets/clear.jpg);
  .mdi-magnify, .location-input{
    color:#333;
  }
  .selected{
    color:#427d9b;
  }
  .inline-input{
    border-bottom: 1px dotted #333;
  }
}

.snow {
  background-image: url(../assets/snow.jpg);
  .mdi-magnify, .location-input{
    color:#333;
  }
  .selected{
    color:#427d9b;
  }
  .inline-input{
    border-bottom: 1px dotted #333;
  }
}

.rain {
  background-image: url(../assets/rain.jpg);
  .main-display, .temp-measurement, .sub-info-text, .selection-icons, .selection-days{
    color: #fff;
  }
  .selected{
    color:#D0FCB3;
  }
}

.drizzle {
  background-image: url(../assets/drizzle.jpg);
  .main-display, .temp-measurement, .sub-info-text, .selection-icons, .selection-days{
    color: #fff;
  }
  .selected{
    color:#D0FCB3;
  }
}

.thunderstorm {
  background-image: url(../assets/thunderstorm.jpg);
  .main-display, .temp-measurement, .sub-info-text, .selection-icons, .selection-days{
    color: #fff;
  }
  .selected{
    color:#D0FCB3;
  }
}