@mixin media-max($_max-width) {
    @media screen and (max-width: $_max-width) {
        &{ @content; }
    }
}

@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

@mixin media-min-max($_mmin-width, $_mmax-width) {
    @media screen and (min-width: $_mmin-width) and (max-width: $_mmax-width) {
        &{ @content; }
    }
}

$header-color:#3e5b5fcc;
$primary-color-dark: #3E5B5F;
$primary-color-light:#D0FCB3;
$primary-color-middle:#4DAB10;
$primary-color-darklight:#708D91;
$client-color: rgb(200, 0, 0);
$prospect-color: rgb(0, 0, 200);

.browser-home-content {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    height: 100%;
    gap: 50px;
    padding: 50px;
    transition: 300ms;
    overflow: auto;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    @include media-max(1224px){
        padding: 30px 30px 30px 90px;
        gap: 30px;
    }

    .menuRapide{
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: space-between;
        align-items: baseline;
        column-gap: 20px;

        a.box{
            color: #3E5B5F;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;
            flex: 1 1;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            .text-center{
                img{
                    width: 32px;
                    margin-bottom: 5px;
                }
            }
        }
    }
  
    .browser-home-box {  
        display: flex;
        flex-direction: column;
        border-radius: 10px;

        &.statistiques {
            flex: 2;
            .content{
                h3{
                    flex-basis: 50%;
                }
                p{
                    flex-basis: 50%;
                    text-align: right;
                }
                .item{
                    margin-bottom: 20px;
                }
            }
        }

        &.mesrdv {
            flex: 4;
            .content{
                h3{
                   margin-bottom: 15px;
                }
            }
        }

        &.rapport-activite {
            flex: 2;
        }
    
        .header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5rem 1rem 0.5rem 1rem;
            border-radius: 10px 10px 0px 0px;
            color: white;
            background-color: $header-color;
            font-size: 1rem;
        }
    
        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 25px;
            background-color: #fff;
            border-radius: 0 0 10px 10px;

            .group{
                margin-bottom: 25px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        
            .item {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 7px;
                p {
                color: $primary-color-dark;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0;
                }
                &:nth-child(2n){
                    p{
                        color: $primary-color-darklight;
                    }  
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            
            h3{
                color: $primary-color-middle;
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 0;
            }
        }
    }

    .browser-home-widget-meteo {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        border-radius: 10px;
      
        .header {
          width: 100px;
          max-width: 100px;
          min-width: 100px;
      
          display: flex;
          justify-content: center;
          align-items: center;
      
          margin-right: 2px;
          padding: 0.5rem;
      
          border-radius: 10px 0px 0px 10px;
          color: white;
          background-color: $header-color;
        }
      
        .search-container {
          max-width: 240px;
      
          padding: 0.25rem;
      
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
      
          background-color : $header-color;
      
          .svg {
            width: 1em;
            height: 1em;
          }
      
          .search-input {
            height: 100%;
            width: 100%;
      
            padding: 0.25rem;
            margin-left: auto;
            margin-right: auto;
      
            border: none;
            background-color: transparent;
            text-align: left;
      
            color: white;
      
            &:focus, &:hover {
              outline: none;
            }
      
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: white !important;
              opacity: 1; /* Firefox */
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
             color: white !important;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
             color: white !important;
            }
          }
        }
    }
      
    .datetemp-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      width: 100%;
  
      margin-right: 2px;
      padding: 0.5rem;
  
      p {
        margin-bottom: 0;
      }
  
      p:last-of-type {
        margin-left: auto;
      }
    }
}

.mobile-content{
    &.home{
       height: calc(100vh - 50px);
    }
    .mobileHomecontent{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: space-between;
        align-items: center;
        row-gap: 5vw;
        column-gap: 5vw;

        a.box{
            display: flex;
            justify-content: center;
            align-items: center;
            color: #3E5B5F;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            width: 42.5vw;
            height: 42.5vw;
            .text-center{
                img{
                    width: 32px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}